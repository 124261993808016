<template>
	<div class="about">
		<h1>About</h1>

		<p>
			This application is called <strong>Random Raffler</strong>. It was
			written by Adam Presley from <em>App Nerds LLC</em>.
		</p>

		<p>
			<img
				src="../assets/im-kind-of-a-big-deal.gif"
				alt="I'm kind of a big deal GIF"
			/>
		</p>

		<button
			class="home-button floating-button"
			@click="goHome"
			alt="Go to the home page"
			title="Go to the home page"
		>
			<font-awesome-icon icon="home"></font-awesome-icon>
		</button>
	</div>
</template>

<style scoped>
.home-button {
	bottom: 40px;
}
</style>

<script>
export default {
	name: "About",

	methods: {
		goHome() {
			this.$router.push({ name: "Home" });
		},
	},
};
</script>
